import React from "react";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import Feature from "./Feature";
import PropTypes from "prop-types";
import * as FeatureOverviewStyling from "./FeatureOverview.module.scss";
import { graphql } from "gatsby";

const FeatureOverview = ({ block }) => {
  const title = block.title;
  const features = block.features;
  return (
    <Section className={FeatureOverviewStyling.featureOverviewWrapper}>
      <p className={`text7 m-8 ${FeatureOverviewStyling.title}`}>{title}</p>
      {features.map((feature) => {
        return (
          <Feature
            key={feature.id}
            title={feature.title}
            subtitle={feature.subtitle}
            image={feature.featureImage}
          />
        );
      })}
    </Section>
  );
};

export default FeatureOverview;
FeatureOverview.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        featureImage: PropTypes.shape({
          alt: PropTypes.string,
          gatsbyImageData: PropTypes.object,
        }),
      })
    ).isRequired,
  }),
};

export const query = graphql`
  fragment FeatureOverview on DatoCmsFeatureoverview {
    model {
      apiKey
    }
    id: originalId
    title
    features {
      ...Feature
    }
  }
`;
