import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as FeatureOverviewStyling from "./FeatureOverview.module.scss";
import { graphql } from "gatsby";

const Feature = ({ title, subtitle, image }) => {
  return (
    <div className={FeatureOverviewStyling.feature}>
      <div className={`row ${FeatureOverviewStyling.featureRow}`}>
        <p className={`col m-5 ${FeatureOverviewStyling.featureTitle}`}>
          {title}
        </p>
        <div className="col m-2 hide-to-s"></div>
        <p className="col m-5 text4 subtle">{subtitle}</p>
      </div>
      <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
    </div>
  );
};

export default Feature;

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    gatsbyImageData: PropTypes.object.isRequired,
  }),
};

export const query = graphql`
  fragment Feature on DatoCmsFeature {
    id: originalId
    title
    subtitle
    featureImage {
      alt
      gatsbyImageData
    }
  }
`